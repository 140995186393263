import React, { Component } from 'react';
import { ReactiveBase, CategorySearch, SelectedFilters, SingleDropdownRange, ToggleButton, MultiList, TagCloud, ReactiveList} from '@appbaseio/reactivesearch';

import './index.css';

class App extends Component {
	constructor() {
		super();
		this.state = {
			smallFilter: true
		};
		this.showHide = this.showHide.bind(this);
	}

	showHide() {
		this.setState({ smallFilter: !this.state.smallFilter });
	}

	render() {
		const { smallFilter } = this.state;
		return (
			<ReactiveBase
				app="books/book"
				url="http://api.mwauzel.com:9200"
			>
				<div style={{ width: '100%', display: 'flex' }}>
					<img src="/logo180.png" style={{ width: '180px' }}/>
					<div style={{ textAlign: 'right', width: '90%' }}>
						<h1 style={{ display: 'inline' }}>
							MWauzel.com,&nbsp;
						</h1>
						<h3 style={{ display: 'inline', verticalAlign: 'middle', textAlign: 'center' }}>
							All your classroom needs with over 11,000 ebooks
						</h3>
					</div>
				</div>
				<div className="book-col">
					<div className="book-col-fil">
						<div style={{ width: '100%' }}>
							<CategorySearch
								componentId="searchbox"
								dataField="search_everything"
								placeholder="Search for books"
								categoryField="title.keyword"
								filterLabel="Search Phrase"
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>

							<button className="hidden-small hidden-button" onClick={() => this.showHide()} style={{ display: !smallFilter ? 'initial' : 'none' }}>
								More
							</button>
						</div>
						<div id="smallFilter" style={{ width: '100%', display: smallFilter ? 'initial' : 'none' }}>
							<button className="hidden-small hidden-button" onClick={() => this.showHide()} >
								Less
							</button>

							<SingleDropdownRange
								componentId="filtergrade"
								dataField="age_range"
								data={
									[{"start": 0, "end": 4, "label": "Pre K"},
									{"start": 5, "end": 6, "label": "K"},
									{"start": 7, "end": 7, "label": "1"},
									{"start": 8, "end": 8, "label": "2"},
									{"start": 9, "end": 9, "label": "3"},
									{"start": 10, "end": 10, "label": "4"},
									{"start": 11, "end": 11, "label": "5"},
									{"start": 12, "end": 12, "label": "6"},
									{"start": 13, "end": 13, "label": "7"},
									{"start": 14, "end": 14, "label": "8"},
									{"start": 15, "end": 15, "label": "9"},
									{"start": 16, "end": 16, "label": "10"},
									{"start": 17, "end": 17, "label": "11"},
									{"start": 18, "end": 18, "label": "12"}]
								}
								placeholder="Select a Grade Level"
								filterLabel="Grade Level"
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
							<ToggleButton
								componentId="filterlearning_proficiency"
								dataField="learning_proficiency.keyword"
								data={[
									{"label": "Reading Comprehension", "value": "Reading Comprehension"}
									,{"label": "Writing Proficiency", "value": "Writing Proficiency"}
									,{"label": "Verbal Communication", "value": "Verbal Communication"}
									,{"label": "Analytical Research", "value": "Analytical Research"}
									,{"label": "Applied Mathematics", "value": "Applied Mathematics"}
								]}
								title="Literature Learning Proficiency (LLP)"
								multiSelect={true}
								showFilter={true}
								filterLabel="LLP"
								URLParams={false}
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
							<MultiList
								componentId="filterlanguages"
								dataField="languages.keyword"
								title="Language"
								size={100}
								sortBy="asc"
								queryFormat="or"
								selectAllLabel="All Languages"
								showCheckbox={true}
								showCount={true}
								showSearch={true}
								placeholder="Search Languages"
								showFilter={true}
								filterLabel="Language"
								URLParams={false}
								loader="Loading ..."
								react={{
									and: ['searchbox', 'filtergrade', 'filterlearning_proficiency', 'filtercc_text_type', 'filtergenres', 'filterkeywords'],
								}}
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
							<MultiList
								componentId="filtergenres"
								dataField="genres.keyword"
								title="Genre"
								size={50}
								sortBy="asc"
								queryFormat="or"
								selectAllLabel="All Genres"
								showCheckbox={true}
								showCount={true}
								showSearch={true}
								placeholder="Search Genres"
								showFilter={true}
								filterLabel="Genre"
								URLParams={false}
								loader="Loading ..."
								react={{
									and: ['searchbox', 'filtergrade', 'filterlearning_proficiency', 'filtercc_text_type', 'filterlanguages', 'filterkeywords'],
								}}
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
							<ToggleButton
								componentId="filtercc_text_type"
								dataField="cc_text_type.keyword"
								data={[
									{"label": "Argumentative", "value": "Argumentative"}
									,{"label": "History/Social Sciences", "value": "History/Social Sciences"}
									,{"label": "Information/Informative", "value": "Information/Informative"}
									,{"label": "Narrative", "value": "Narrative"}
								]}
								title="Common Core Text Type (CCTT)"
								multiSelect={true}
								showFilter={true}
								filterLabel="CCTT"
								URLParams={false}
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
							<TagCloud
								componentId="filterkeywords"
								dataField="keywords.keyword"
								title="Popular Keywords"
								size={32}
								sortby="count"
								showCount={true}
								multiSelect={true}
								queryFormat="or"
								showFilter={true}
								filterLabel="Keywords"
								URLParams={false}
								loader="Loading ..."
								react={{
									and: ['searchbox', 'filtergrade', 'filterlearning_proficiency', 'filtercc_text_type', 'filtergenres', 'filterlanguages'],
								}}
								style={{
									padding: '5px',
									marginTop: '10px',
								}}
							/>
						</div>
					</div>
					<div className="book-col-res">
						<div className="hidden-small div-hr"> &nbsp; </div>

						<SelectedFilters
							showClearAll={true}
							clearAllLabel="Clear filters"
							style={{
								padding: '5px',
								marginTop: '10px',
							}}
						/>
						<ReactiveList
							componentId="result"
							title="Results"
							dataField="search_everything"
							from={0}
							size={6}
							style={{
								textAlign: 'center',
							}}
							renderItem={this.booksReactiveList}
							pagination={true}
							react={{
								and: ['searchbox', 'filtergrade', 'filterlearning_proficiency', 'filtercc_text_type', 'filtergenres', 'filterlanguages', 'filterkeywords'],
							}}
						/>
					</div>
				</div>
			</ReactiveBase>
		);
	}


	booksReactiveList(data) {
		return (
			<div className="flex book-content" key={data._id} style={{ display: data.title ? 'initial' : 'none' }} >
				<a href={"http://mwauzel.com/books/getbook/"+data.id+"/"+data.title} target="_blank" alt={"Click to Download" + data.title} className="book-image" >
					<img src={"http://mwauzel.com/books/getcover/" + data.url_cover.replace(/assets\/images\//gi, '')} alt={data._id} alts="Book Cover" className="book-image" />
				</a>
				<div className="flex column justify-center" style={{ marginLeft: 20 }}>
					<div className="book-header">
						<a href={"http://mwauzel.com/books/getbook/"+data.id+"/"+data.title} target="_blank" alt={"Click to Download" + data.title}>
							{data.title}
						</a>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.authors)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">by </span> {typeof((data.authors)) == 'undefined' ? 'initial' : (data.authors).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.illustrators)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Illustrated by </span> {typeof((data.illustrators)) == 'undefined' ? 'initial' : (data.illustrators).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.editors)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Editor </span> {typeof((data.editors)) == 'undefined' ? 'initial' : (data.editors).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.translators)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Translated by </span> {typeof((data.translators)) == 'undefined' ? 'initial' : (data.translators).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.publishers)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Published by </span> {typeof((data.publishers)) == 'undefined' ? 'initial' : (data.publishers).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: data.grade_start ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Grade Level </span> <span className="list-item">{data.grade_start} to {data.grade_end}</span>
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.learning_proficiency)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Literature Learning Proficiency </span> {typeof((data.learning_proficiency)) == 'undefined' ? 'initial' : (data.learning_proficiency).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.cc_text_type)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Common Core Text Type </span> {typeof((data.cc_text_type)) == 'undefined' ? 'initial' : (data.cc_text_type).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.genres)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Genre </span> {typeof((data.genres)) == 'undefined' ? 'initial' : (data.genres).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className='flex column justify-space-between' style={{ display: Array(data.keywords)[0]!="" ? 'initial' : 'none' }} >
						<div>
							<span className="list-header">Keywords</span> {typeof((data.keywords)) == 'undefined' ? 'initial' : (data.keywords).map(child => <span className="list-item">{child}</span>)}
						</div>
					</div>
					<div className="flex column justify-space-between book-content" style={{display: data.description ? 'initial' : 'none'}, {textAlign: 'left'}} >
						{data.description}
					</div>
				</div>
			</div>
		);
	}
}

export default App;